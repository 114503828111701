<template>
  <div class="gallery-item">
    <div class="img-wrapper">
      <img :src="imageSrc" :alt="image.photo">
    </div>
    <el-form @submit.native.prevent class="gallery-item__form">
      <el-form-item
          :label="$t('page_unit_gallery_manage.image_name_label')"
      >
        <el-input @change="handleChangeTitle" v-model="image.title"></el-input>
      </el-form-item>
    </el-form>
    <div class="gallery-item__actions">
      <el-popconfirm
          class="popconfirm-btn"
          @confirm="handleDeleteImage"
          :confirm-button-text="$t('page_unit_gallery_manage.popover_delete.btn_confirm')"
          :cancel-button-text="$t('page_unit_gallery_manage.popover_delete.btn_cancel')"
          icon="el-icon-info"
          :title="$t('page_unit_gallery_manage.popover_delete.confirm_text')"
          icon-color="red"
      >
        <template #reference>
          <v-button class="remove-btn" :loading="isRemoveInProgress" icon="delete">
            {{ !isSm ? $t('page_unit_gallery_manage.btn_remove') : '' }}
          </v-button>
        </template>
      </el-popconfirm>
    </div>
  </div>
</template>

<script>
import VButton from '../../../../../components/ui/v-button/VButton'
import { UPLOADS_FOLDER_URL } from '../../../../../shared/constants/backend'
import {
  removeImage,
  updateImageInfo
} from '../../../shared/services/gallery-service'

export default {
  name: 'UnitGalleryItem',
  components: {VButton},
  props: {
    image: {
      type: Object,
      required: true
    }
  },
  inject: ['responsive'],
  data() {
    return {
      isRemoveInProgress: false
    }
  },
  computed: {
    imageSrc() {
      return `${UPLOADS_FOLDER_URL}/${this.image.photo}`
    },
    isSm() {
      return this.responsive.isMd
    }
  },
  methods: {
    handleChangeTitle() {
      this.isLoading = true
      const payload = {
        title: this.image.title
      }
      updateImageInfo(this.image.photoId, payload)
          .then(() => {
            this.$message({
              type: 'success',
              message: `${this.$t('page_unit_gallery_manage.messages.edit_success')}`
            })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: `${this.$t('page_unit_gallery_manage.messages.edit_error')}`
            })
          })
          .finally(() => {
            this.isLoading = false
          })
    },
    handleDeleteImage() {
      this.isRemoveInProgress = true
      removeImage(this.image.photoId)
        .then(() => {
          this.$emit('image-deleted')
          this.$message({
            type: 'success',
            message: `${this.$t('page_unit_gallery_manage.messages.remove_success')}`
          })
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: `${this.$t('page_unit_gallery_manage.messages.remove_error')}`
          })
        })
        .finally(() => {
          this.isRemoveInProgress = false
        })
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/grid-mixins";
.gallery-item {
  display: grid;
  grid-template-rows: repeat(5, 21px);
  grid-template-columns: 1fr 5fr auto;
  column-gap: 12px;
  align-self: end;
  margin-bottom: 1.2em;
  @include smartphone {
    grid-template-rows: repeat(4, 50px);
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 1em;
  }
  &__form {
    grid-column: 2;
    grid-row: 2 / 5;
    @include smartphone {
      grid-column: 1 / 4;
      grid-row: 4 / 5;
    }
  }
  &__actions {
    grid-column: 3;
    grid-row: 3 / 5;
    @include smartphone {
      grid-column: 4;
      grid-row: 4 / 5;
    }
  }
}
.img-wrapper {
  grid-column: 1;
  grid-row: 1 / 6;
  @include smartphone {
    grid-column: 1 / 5;
    grid-row: 1 / 4;
  }
  position: relative;
  overflow: hidden;
  height: 100%;
  border-radius: 0.8em;
  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}
</style>
