<template>
<div>
  <VButton
      @click="openDialog"
      icon="upload"
  >
    {{ $t('page_unit_gallery_manage.upload_modal.btn_open') }}
  </VButton>
  <el-dialog
      v-loading="isLoading"
      destroy-on-close
      custom-class="upload-dialog"
      width="420px"
      :visible.sync="showDialog"
  >
    <template #title>
      <h3 class="heading heading--dialog">
        {{ $t('page_unit_gallery_manage.upload_modal.title') }}
      </h3>
    </template>
    <template #default>
      <el-upload
          drag
          action=""
          :auto-upload="false"
          :on-remove="handleChange"
          :on-change="handleChange"
          :file-list="fileList"
          multiple
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          {{ $t('page_unit_gallery_manage.upload_modal.upload_text') }}
          <em>
            {{ $t('page_unit_gallery_manage.upload_modal.upload_text_highlighted') }}
          </em>
        </div>
      </el-upload>
    </template>
    <template #footer class="dialog-footer">
      <el-button @click="closeDialog">
        {{ $t('page_unit_gallery_manage.upload_modal.btn_cancel') }}
      </el-button>
      <el-button type="primary" @click="uploadImages">
        {{ $t('page_unit_gallery_manage.upload_modal.btn_confirm') }}
      </el-button>
    </template>
  </el-dialog>
</div>
</template>

<script>
import {uploadPhotosToUnitGallery} from '../../../shared/services/gallery-service'

import VButton from '../../../../../components/ui/v-button/VButton'
export default {
  name: 'UnitGalleryUpload',
  components: {VButton},
  data() {
    return {
      fileList: [],
      showDialog: false,
      isLoading: false,
    }
  },
  watch: {
    showDialog(show) {
      if (!show) {
        this.fileList = []
      }
    }
  },
  methods: {
    handleChange(_, fileList) {
      this.fileList = fileList
    },
    openDialog() {
      this.showDialog = true
    },
    closeDialog() {
      this.showDialog = false
    },
    handleClose() {
      this.fileList = []
    },
    uploadImages() {
      this.isLoading = true
      const { unitID } = this.$route.params
      uploadPhotosToUnitGallery(this.fileList, unitID)
        .then(() => {
          this.closeDialog()
          this.$emit('images-uploaded')
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: 'error'
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  computed: {
    totalFileSize() {
      const reducer = (acc, currentItem) => {
        return acc + currentItem.size
      }
      const INIT_VALUE = 0
      return this.fileList.reduce(reducer, INIT_VALUE)
    }
  }
}
</script>

<style lang="scss">
.heading--dialog {
  font-weight: 500;
  font-size: 1.3em;
}
.upload-dialog {
  .el-dialog__body {
    padding-top: 0;
  }
}
.el-upload-dragger {
  width: auto;
}
.el-upload--text {
  width: 100%;
}
</style>
