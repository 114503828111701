<template>
  <div>
    <UnitGalleryItem
        v-for="img in galleryImages"
        :key="img.imageId"
        :image="img"
        @image-deleted="handleDeleteImage"
    />
  </div>
</template>

<script>
import UnitGalleryItem from '../unit-gallery-item/UnitGalleryItem'
export default {
  name: 'UnitGallery',
  components: {UnitGalleryItem},
  props: {
    galleryImages: {
      type: Array,
      require: true
    }
  },
  methods: {
    handleDeleteImage() {
      this.$emit('image-deleted')
    }
  }
}
</script>

<style scoped>

</style>
