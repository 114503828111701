<template>
  <el-row>
    <el-col :lg="{span: 14, offset: 5}">
      <el-card v-loading.body="isLoading">
        <template #header>
          <div>
            <h2 class="heading page-heading">
              {{ $t('page_unit_gallery_manage.title') }}
            </h2>
            <CurrentUnitInfo />
          </div>
        </template>
        <template #default>
          <div class="unit-actions d-flex">
            <v-button @click="backToGallery" class="btn-back" icon="arrow_back">
              {{ $t('page_unit_gallery_manage.btn_back') }}
            </v-button>
            <UnitGalleryUpload @images-uploaded="fetchGalleryImages" />
          </div>
          <UnitGallery
              @image-deleted="fetchGalleryImages"
              :gallery-images="galleryImages"
          />
        </template>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import {
  fetchUnitGallery,
} from '../shared/services/gallery-service'

import {
  NOT_FOUND_ROUTE_NAME,
  UNIT_GALLERY_ROUTE_NAME
} from '../../../shared/constants/app-routes-names'
import UnitGallery from './components/unit-gallery/UnitGallery'
import UnitGalleryUpload from './components/unit-gallery-upload/UnitGalleryUpload'
import VButton from '../../../components/ui/v-button/VButton'
import CurrentUnitInfo from '../components/current-unit-info/CurrentUnitInfo'

export default {
  name: 'UnitGalleryManage',
  components: {CurrentUnitInfo, VButton, UnitGalleryUpload, UnitGallery},
  data() {
    return {
      isLoading: false,
      galleryImages: []
    }
  },
  created() {
    this.fetchGalleryImages()
  },
  methods: {
    backToGallery() {
      const { unitID } = this.$route.params
      this.$router.push({
        name: UNIT_GALLERY_ROUTE_NAME,
        params: {
          unitID
        }
      })
    },
    fetchGalleryImages() {
      const { unitID } = this.$route.params
      this.isLoading = true
      fetchUnitGallery(unitID)
        .then((res) => {
          this.galleryImages = res
        })
        .catch(() => {
          this.$router.push({name: NOT_FOUND_ROUTE_NAME})
        })
          .finally(() => {
          this.isLoading = false
        })
    },
  }
}
</script>

<style scoped lang="scss">
.unit-actions {
  .btn-back {
    margin-right: 1em;
  }
}
</style>
